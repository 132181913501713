export const fetchTags = async (userId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag?user_id=${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching tags:', error);
        throw error;
    }
};
