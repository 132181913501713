import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Box, CircularProgress } from '@mui/material';

function CustomChatPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [chatbotName, setChatbotName] = useState('');
  const [chatbots, setChatbots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [editingChatbotId, setEditingChatbotId] = useState(null);
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    fetchChatbots();
  }, []);

  const fetchChatbots = () => {
    fetch(`${process.env.REACT_APP_API_URL}/chatbot?user_id=${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setChatbots(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching chatbots:', error);
        setIsLoading(false);
      });
  };

  const handleClickOpen = (chatbot = null) => {
    if (chatbot) {
      setChatbotName(chatbot.name);
      setEditingChatbotId(chatbot.id);
    } else {
      setChatbotName('');
      setEditingChatbotId(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setIsLoading(true);
    const url = editingChatbotId
      ? `${process.env.REACT_APP_API_URL}/chatbot/${editingChatbotId}/`
      : `${process.env.REACT_APP_API_URL}/chatbot/`;
    const method = editingChatbotId ? 'PUT' : 'POST';

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
      body: JSON.stringify({ name: chatbotName, user_id: userId }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Chatbot saved:', data);
        // Close the dialog
        handleClose();
        // Refresh the chatbots list
        fetchChatbots();
      })
      .catch(error => {
        console.error('Error saving chatbot:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedChatbots = (chatbots) => {
    return [...chatbots].sort((a, b) => {
      const aValue = a[orderBy] ?? '';
      const bValue = b[orderBy] ?? '';
      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });
  };

return (
    <Layout searchQuery={searchQuery}>
        <Button variant="contained" color="primary" onClick={() => handleClickOpen()}>
            Add New Chatbot
        </Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{editingChatbotId ? 'Edit Chatbot' : 'Add New Chatbot'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter the name of the chatbot.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Chatbot Name"
                    type="text"
                    fullWidth
                    value={chatbotName}
                    onChange={(e) => setChatbotName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>

        {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        ) : (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="chatbots table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'name'}
                                    direction={orderBy === 'name' ? order : 'asc'}
                                    onClick={() => handleRequestSort('name')}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Manage Files</TableCell>
                            <TableCell>Chat</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedChatbots(chatbots).map((chatbot, index) => (
                            <TableRow key={index}>
                                <TableCell>{chatbot.name}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" onClick={() => handleClickOpen(chatbot)}>
                                        Edit
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" href={`/files/${chatbot.id}`}>
                                        Manage Files
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" href={`/message/${chatbot.id}`}>
                                        Chat
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )}
    </Layout>
);
}

export default CustomChatPage;