import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, FormControl, InputLabel, Select, MenuItem, Typography, Tooltip } from '@mui/material';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import truncateText from './utils/truncateText';

function TextTab({ id }) {
  const [textData, setTextData] = useState([]);
  const [isTextDataFetched, setIsTextDataFetched] = useState(false);
  const [billData, setBillData] = useState([]);
  const [selectedTextId, setSelectedTextId] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [messages, setMessages] = useState([]);
  const iframeRef = useRef(null);
  const messageIds = [];
  const userId = localStorage.getItem('user_id');
  const location = useLocation();
  const [toggledDescription, setToggledDescription] = useState('');
  const [toggledPolicyObjective, setToggledPolicyObjective] = useState('');
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullPolicyObjective, setShowFullPolicyObjective] = useState(false);
  const orgPackage = localStorage.getItem('package');
  const [remainingChats, setRemainingChats] = useState(0);

  useEffect(() => {
    if (orgPackage === 'Free') {
      fetch(`${process.env.REACT_APP_API_URL}/chat-remaining/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          setRemainingChats(data.remaining_chats);
        })
        .catch(error => console.error('Error fetching remaining chats:', error));
    } else {
      setRemainingChats(100);
    }
  }, [orgPackage, userId]);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const togglePolicyObjective = () => {
    setShowFullPolicyObjective(!showFullPolicyObjective);
  };

  useEffect(() => {
    let isMounted = true;
    
    if (!isTextDataFetched) {
      fetch(`${process.env.REACT_APP_API_URL}/bill-text/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if (isMounted) {
            setTextData(data.text);
            setBillData(data.bill);
            
            setToggledDescription(truncateText(data.bill.description || '', 45));
            setToggledPolicyObjective(truncateText(data.bill.policy_objective || '', 45));
            
            setIsTextDataFetched(true);
            if (data.text.length > 0) {
              setSelectedTextId(data.text[0].id);
              let initialFileUrl = `https://api.civiclyenvolved.com/bill-text/${data.text[0].id}/`;
              const queryParams = new URLSearchParams(location.search);
              const originalTextParam = queryParams.get('originalText');
              if (originalTextParam) {
                initialFileUrl += `?q=${encodeURIComponent(originalTextParam)}`;
              }
              setFileUrl(initialFileUrl);
            }
          }
        })
        .catch(error => console.error('Error fetching text data:', error));

        fetch(`${process.env.REACT_APP_API_URL}/user-chat/${userId}/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            data.forEach(item => {
              if (messageIds.includes(item.id)) {
                return;
              }

              console.log(data);
              setMessages(prevMessages => [...prevMessages, { content: item.question_text, direction: 'outgoing' }]);
              setMessages(prevMessages => [...prevMessages, { content: item.answer_text, direction: 'incoming', original: item.original_text }]);
              messageIds.push(item.id);
            });
          })
          .catch(error => console.error('Error fetching user chat data:', error));
    }

    return () => {
      isMounted = false;
    };
  }, [id, isTextDataFetched, location.search, userId]);

  const handleTextChange = (event) => {
    const selectedId = event.target.value;
    setSelectedTextId(selectedId);

    const selectedText = textData.find(text => text.id === selectedId);
    if (selectedText) {
      setFileUrl(selectedText.file_url);
    }
  };

  const updateIframeUrl = (iframeRef, fileUrl, originalText) => {
    if (iframeRef.current) {
      const newUrl = new URL(fileUrl);
      newUrl.searchParams.set('q', originalText);
      iframeRef.current.src = newUrl.toString();
    }
  };

  const handleSendMessage = (message) => {
    setRemainingChats(prevRemainingChats => prevRemainingChats - 1);
    setMessages([...messages, { content: message, direction: 'outgoing' }]);

    const thinkingMessage = { content: "We're thinking...", direction: 'incoming' };
    setMessages(prevMessages => [...prevMessages, thinkingMessage]);    
    
    fetch(`${process.env.REACT_APP_API_URL}/chat/${id}?q=${encodeURIComponent(message)}&user_id=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        
        setMessages(prevMessages => {
          const newMessages = [...prevMessages];
          newMessages[newMessages.indexOf(thinkingMessage)] = { content: data.answer, direction: 'incoming', original: data.original_text };
          return newMessages;
        });

        // Update iframe URL with query parameter
        updateIframeUrl(iframeRef, fileUrl, data.original_text);

      })
      .catch(error => console.error('Error fetching reply:', error));
  };

  return (
    <Box>
      {billData && (
        <Box sx={{ marginBottom: 5 }}>
          {billData.description && billData.description.length > 0 && (
            <>
              <Typography variant="h6">Description</Typography>
              <Typography variant="body1">
                {billData.description.length > toggledDescription.length ? (
                  <>
                    {showFullDescription ? billData.description : toggledDescription}
                    <span onClick={toggleDescription} style={{ cursor: 'pointer', color: 'blue' }}>
                      {showFullDescription ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </span>
                  </>
                ) : (
                  billData.description
                )}
              </Typography>
            </>
          )}
          {billData.policy_objective && billData.policy_objective.length > 0 && (
            <>
              <Typography variant="h6">InformNation Description</Typography>
              <Typography variant="body1">
                {billData.policy_objective.length > toggledPolicyObjective.length ? (
                  <>
                    {showFullPolicyObjective ? billData.policy_objective : toggledPolicyObjective}
                    <span onClick={togglePolicyObjective} style={{ cursor: 'pointer', color: 'blue' }}>
                      {showFullPolicyObjective ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </span>
                  </>
                ) : (
                  billData.policy_objective
                )}
              </Typography>
            </>
          )}
        </Box>
      )}

      <FormControl fullWidth>
        <InputLabel id="text-select-label">Select Text</InputLabel>
        <Select
          sx={{ width: '30vw' }}
          labelId="text-select-label"
          value={selectedTextId}
          label="Select Text"
          onChange={handleTextChange}
        >
          {textData.map((text) => (
            <MenuItem key={text.id} value={text.id}>
              {text.status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box display="flex" alignItems="flex-start" sx={{ marginTop: 2 }}>
        {fileUrl && (
          <Box flex={1}>
            <iframe ref={iframeRef} src={fileUrl} width="100%" height="600px" title="Bill Text" style={{ border: '1px solid #E0E0E0' }} />
          </Box>
        )}

        <Box width="400px" height="600px" marginLeft="16px">
          <MainContainer>
            <ChatContainer>
              <MessageList>
                {messages.map((message, index) => (
                  <Tooltip title={message.original || ''} key={index}>
                    <div>
                      <Message
                        model={{
                          message: message.content,
                          sentTime: 'just now',
                          sender: 'User',
                          direction: message.direction,
                        }}
                        onClick={() => updateIframeUrl(iframeRef, fileUrl, message.original || '')}
                      />
                    </div>
                  </Tooltip>
                ))}
              </MessageList>
              <MessageInput
                placeholder="Type a message..."
                onSend={remainingChats > 0 ? handleSendMessage : () => alert("You've reached your daily max of 4 chats. Your chat allocation will be reset after 24 hours.")}
                attachButton={false}
              />
            </ChatContainer>
          </MainContainer>

          {orgPackage === 'Free' && (
            <Box sx={{ mt: 2 }}>
              <strong>
                Chats Remaining: <span style={{ color: remainingChats === 0 ? 'red' : 'inherit' }}>{remainingChats}</span>
              </strong>
            </Box>
          )}

        <br />
        <b>Disclaimer:</b> AI is experimental and may not always provide accurate answers. Please check the source documents for the most accurate information.

        </Box>
        
      </Box>
    </Box>
  );
}

export default TextTab;