import React, {useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Button
} from '@mui/material';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import truncateText from "../utils/truncateText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";



const NestedTable = ({ details, item }) => {
    const [expandedDescriptions, setExpandedDescriptions] = useState({});
    const [expandedSponsors, setExpandedSponsors] = useState({});

    const showTruncatedText = (text, limit) => {
        const truncated = truncateText(text, limit);
        return text.length > truncated.length;
    };

    const toggleDescription = (id) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const toggleSponsors = (id) => {
        setExpandedSponsors((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };


    return (
        <Box>
            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Urgency</TableCell>
                            <TableCell>Level of Relation</TableCell>
                            <TableCell>Reason</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {details && details.length > 0 ? (
                            details.map((detail, index) => (
                                <TableRow key={index}>
                                    <TableCell>{detail.urgency_level}</TableCell>
                                    <TableCell>{detail.level_of_relation}</TableCell>
                                    <TableCell>{detail.reason}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    No additional details available.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Sponsors</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {item.bill.policy_objective && item.bill.policy_objective.length > 0 ? (
                                    <Typography sx={{ marginTop: 1 }}>
                                        {showTruncatedText(item.bill.policy_objective, 10) ? (
                                            <>
                                                {expandedDescriptions[item.bill.id] ? (
                                                    <>
                                                        {item.bill.policy_objective}
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            startIcon={<ExpandLessIcon />}
                                                            onClick={() => toggleDescription(item.bill.id)}
                                                        >
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        {truncateText(item.bill.policy_objective, 10)}
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            startIcon={<ExpandMoreIcon />}
                                                            onClick={() => toggleDescription(item.bill.id)}
                                                        >
                                                        </Button>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            item.bill.policy_objective
                                        )}
                                    </Typography>
                                ) : item.bill.description && item.bill.description.length > 0 ? (
                                    <Typography sx={{ marginTop: 1 }}>
                                        {showTruncatedText(item.bill.description, 45) ? (
                                            <>
                                                {expandedDescriptions[item.bill.id] ? (
                                                    <>
                                                        {item.bill.description}
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            startIcon={<ExpandLessIcon />}
                                                            onClick={() => toggleDescription(details.bill.id)}
                                                        >
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        {truncateText(item.bill.description, 45)}
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            startIcon={<ExpandMoreIcon />}
                                                            onClick={() => toggleDescription(item.bill.id)}
                                                        >
                                                        </Button>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            item.bill.description
                                        )}
                                    </Typography>
                                ) : (
                                    'No description available'
                                )}
                            </TableCell>
                            <TableCell>
                                {item.sponsors && item.sponsors.length > 0 ? (
                                    <Typography sx={{ marginTop: 1 }}>
                                        {showTruncatedText(item.sponsors, 5) ? (
                                            <>
                                                {expandedSponsors[item.bill.id] ? (
                                                    <>
                                                        {item.sponsors}
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            startIcon={<ExpandLessIcon />}
                                                            onClick={() => toggleSponsors(item.bill.id)}
                                                        >
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        {truncateText(item.sponsors, 5)}
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            startIcon={<ExpandMoreIcon />}
                                                            onClick={() => toggleSponsors(item.bill.id)}
                                                        >
                                                        </Button>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            item.sponsors
                                        )}
                                    </Typography>
                                ) : (
                                    'No sponsors available'
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default NestedTable;
