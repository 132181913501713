import React, { useState } from 'react';
import Layout from './Layout';

function NetworkAnalyticsPage() {
    const [searchQuery, setSearchQuery] = useState('');

    return (
        <Layout searchQuery={searchQuery}>
        <iframe
            src="https://joe-weston.shinyapps.io/Network_Scorecard/"
            style={{ width: '90%', height: '100vh', border: 'none' }}
            title="Network Scorecard"
        />
        </Layout>
    );
}

export default NetworkAnalyticsPage;