import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper,
  CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel,
  Select, MenuItem, IconButton, Badge
} from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import hasFeatureAccess from './utils/featureAccess';
import { fetchTags } from "./api/tags";
import truncateText from './utils/truncateText';
import NestedTable from './components/nestedTable';

function NotificationPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState([]);
  const initialData = useRef([]);
  const [tracking, setTracking] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('user_id');
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [savedTag, setSavedTag] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [expandedSponsors, setExpandedSponsors] = useState({});
  const [currentPage, setCurrentPage] = useState(1); // Current page number in pagination
  const [pageSize, setPageSize] = useState(10); // Number of items displayed per page
  const [totalPages, setTotalPages] = useState(0); // Total number of pages available
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('-created_at');
  const [cachedBillDetails, setCachedBillDetails] = useState({});

  const queryParams = {
    user_id: userId,
    page: currentPage,
    page_size: pageSize,
    ordering: orderBy,
    user_tag__tag: savedTag || '',
  };

  const [expandedBills, setExpandedBills] = React.useState([]);

  const toggleDetails = async (item) => {
    const billId = item.bill.id;
    const url = `${process.env.REACT_APP_API_URL}/user-tag-bill/get-bill-details/?bill_id=${billId}&user_id=${userId}`;
    if (expandedBills.includes(billId)) {
      setExpandedBills((prev) => prev.filter((id) => id !== billId));
    } else {
      if (!cachedBillDetails[billId]) {
        try {
          const response = await fetch(url);
          const data = await response.json();

          setCachedBillDetails((prev) => ({
            ...prev,
            [billId]: data,
          }));
        } catch (error) {
          console.error('Error fetching bill details:', error);
        }
      }
      setExpandedBills((prev) => [...prev, billId]);
    }
  };


  useEffect(() => {
    // Fetch all tags on component mount
    const loadTags = async () => {
      try {
        const result = await fetchTags(userId);
        setTags(result);
      } catch (error) {
        console.error('Error loading tags:', error);
      }
    };

    loadTags();
  }, [userId]);

  useEffect(() => {
    if (!hasFeatureAccess('notifications')) {
      navigate('/access-denied');
    }
  }, [navigate]);

  useEffect(() => {
    async function fetchData(params) {
      try {
        setLoading(true);

        const queryString = buildQueryString(params);
        const url = `${process.env.REACT_APP_API_URL}/user-tag-bill?${queryString}`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const ret = await response.json();

        const user_tag_bills = ret?.user_tag_bills?.results || [];
        const user_bills = ret?.user_bills || [];
        const totalPages = Math.ceil((ret?.user_tag_bills?.count || 0) / pageSize);

        initialData.current = user_tag_bills;
        setData(user_tag_bills);
        setTracking(user_bills);
        setTotalPages(totalPages);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData(queryParams);
  }, [userId, currentPage, orderBy, order, savedTag]);

  const buildQueryString = (params) => {
    return Object.entries(params)
        .filter(([_, value]) => value !== undefined && value !== '') // Filter out empty or undefined values
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
  }

  const handleFilterOpen = () => {
    setSelectedTag(savedTag);
    setIsFilterDialogOpen(true);
  };

  const handleFilterClose = () => {
    setSelectedTag(savedTag);
    setIsFilterDialogOpen(false);
  };

  const handleSelectChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleFilterSave = () => {
    setSavedTag(selectedTag);
    setCurrentPage(1);
    setIsFilterDialogOpen(false);
  };

  const transformOrderBy = (property, order) => {
    return order === 'desc' ? `-${property}` : property;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';

    const transformedOrderBy = transformOrderBy(property, newOrder);

    setOrder(newOrder);
    setOrderBy(transformedOrderBy);
    setCurrentPage(1);
  };

  const stripMinus = (value) => value.startsWith('-') ? value.slice(1) : value;

  const handleBookmarkClick = async (billId, action) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/save-user-bill/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify({ bill_id: billId, user_id: userId, action: action }),
      });

      if (response.ok) {
        setTracking((prevTracking) => {
          if (action === 'add') {
            return [...prevTracking, billId];
          } else {
            return prevTracking.filter((id) => id !== billId);
          }
        });
      } else {
        console.error('Error saving user bill:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving user bill:', error);
    }
  };

  const renderPageNumbers = () => {
    const maxPagesToShow = 5;
    const pages = [];

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push("...");

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (currentPage < totalPages - 2) pages.push("...");
      pages.push(totalPages);
    }

    return pages.map((page, index) =>
        typeof page === "number" ? (
            <Button
                key={index}
                variant={currentPage === page ? "contained" : "outlined"}
                size="small"
                onClick={() => setCurrentPage(page)}
                sx={{
                  margin: "0 4px",
                  minWidth: 30,
                  ...(currentPage === page && { backgroundColor: "primary.main", color: "white" }),
                }}
            >
              {page}
            </Button>
        ) : (
            <Typography key={index} sx={{ margin: "0 8px", fontSize: "14px" }}>
              ...
            </Typography>
        )
    );
  };

  return (
    <Layout searchQuery={searchQuery}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ paddingBottom: 4 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Button variant="outlined" color="primary" onClick={handleFilterOpen}>
              Filter
            </Button>
          </Box>

          <Dialog
            open={isFilterDialogOpen}
            onClose={handleFilterClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Filter by Tag</DialogTitle>
            <DialogContent sx={{
              paddingTop: '8px !important',
              marginTop: '8px',
            }}>
              <FormControl fullWidth>
                <InputLabel id="text-select-label">Select a Tag</InputLabel>
                <Select
                  labelId="text-select-label"
                  value={selectedTag}
                  label="Select a Tag"
                  onChange={handleSelectChange}
                  renderValue={(selected) =>
                    selected && (
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <span>{selected}</span>
                        <Button
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            setSelectedTag('');
                          }}
                          sx={{
                            minWidth: '24px',
                            height: '24px',
                            padding: 0,
                            marginLeft: '8px',
                          }}
                        >
                          ✕
                        </Button>
                      </Box>
                    )
                  }
                >
                  {tags.map((tag) => (
                    <MenuItem key={tag.id} value={tag.tag}>
                      {tag.tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFilterClose}>Cancel</Button>
              <Button onClick={handleFilterSave} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {data.length === 0 ? (
            <p>No New Notifications</p>
          ) : (
            <>
              <h2>We found the following bills based on your tags:</h2>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        <TableSortLabel
                            active={stripMinus(orderBy) === 'created_at'}
                            direction={stripMinus(orderBy) === 'created_at' ? order : 'asc'}
                            onClick={() => handleRequestSort('created_at')}
                        >
                          Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                            active={stripMinus(orderBy) === 'bill__title'}
                            direction={stripMinus(orderBy) === 'bill__title' ? order : 'asc'}
                            onClick={() => handleRequestSort('bill__title')}
                        >
                          Title
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                            active={stripMinus(orderBy) === 'bill__state'}
                            direction={stripMinus(orderBy) === 'bill__state' ? order : 'asc'}
                            onClick={() => handleRequestSort('bill__state')}
                        >
                          State
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                            active={stripMinus(orderBy) === 'bill__bill_number'}
                            direction={stripMinus(orderBy) === 'bill__bill_number' ? order : 'asc'}
                            onClick={() => handleRequestSort('bill__bill_number')}
                        >
                          Bill Number
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Tags</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item, index) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell>
                            <IconButton
                                size="small"
                                onClick={() => toggleDetails(item)}
                                aria-label={expandedBills.includes(item.bill.id) ? 'Collapse' : 'Expand'}
                            >
                              {expandedBills.includes(item.bill.id) ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell>{item.created_at ? new Date(item.created_at).toLocaleDateString() : ''}</TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Typography onClick={() => handleBookmarkClick(item.bill.id, tracking.includes(item.bill.id) ? 'remove' : 'add')}>
                                {tracking.includes(item.bill.id) ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                              </Typography>
                              <a href={`/bill/${item.bill.id}?tab=1`} target="_blank" rel="noopener noreferrer">
                                {item.bill.title}
                              </a>
                            </Box>
                          </TableCell>
                          <TableCell>{item.bill.state}</TableCell>
                          <TableCell>{item.bill.bill_number}</TableCell>
                          <TableCell>
                            {item.bill.all_tags && item.bill.all_tags.length > 0
                                ? item.bill.all_tags.join(', ')
                                : 'No tags available'}
                          </TableCell>
                        </TableRow>
                        {expandedBills.includes(item.bill.id) && (
                          <TableRow>
                            <TableCell colSpan={6} style={{ padding: 0 }}>
                              <NestedTable
                                  details={cachedBillDetails[item.bill.id] || []}
                                  item={item}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={2}
                  sx={{ maxWidth: 400, overflow: "hidden", whiteSpace: "nowrap" }}
              >
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    sx={{ marginRight: 1, minWidth: 50 }}
                >
                  Previous
                </Button>

                {renderPageNumbers()}

                <Button
                    variant="contained"
                    size="small"
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    sx={{ marginLeft: 1, minWidth: 50 }}
                >
                  Next
                </Button>
              </Box>

            </>
          )}
        </Box>
      )}
    </Layout>
  );
}

export default NotificationPage;