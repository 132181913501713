import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Layout from './Layout';
import { Box, Button, Typography, TableContainer, Paper, Table, TableCell, TableHead, TableBody, TableRow, TableSortLabel, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import hasFeatureAccess from './utils/featureAccess';

function ScorecardPage() {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const userId = localStorage.getItem('user_id');
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [tags, setTags] = useState([]);
    const [evidence, setEvidence] = useState([]);

    const [loading, setLoading] = useState(false);
    const [dialogLoading, setDialogLoading] = useState(false);

    const [bioDialogOpen, setBioDialogOpen] = useState(false);
    const [bioText, setBioText] = useState('');

    useEffect(() => {
        if (!hasFeatureAccess('scorecard')) {
          navigate('/access-denied');
        }
      }, [navigate]);

    const handleBioClick = (bio) => {
        setBioText(bio);
        setBioDialogOpen(true);
    };

    const handleBioDialogClose = () => {
        setBioDialogOpen(false);
        setBioText('');
    };

    const getEvidence = async (attribution_id) => {
        setDialogLoading(true);
        setOpen(true);
                try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/attribution/${attribution_id}/evidence`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                }
            });
            const data = await response.json();
            setEvidence(data);
            setDialogLoading(false);
        } catch (error) {
            console.error('Error fetching candidate feed:', error);
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/candidates/by-state/${userId}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                    }
                });
                const ret = await response.json();
                setData(ret);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // Fetch all tags on component mount
        const fetchTags = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag?user_id=${userId}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
              },
            });
    
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
    
            const result = await response.json();
            setTags(result);
          } catch (error) {
            console.error('Error fetching tags:', error);
          }
        };
    
        fetchTags();
      }, [userId]);

    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const sortedData = React.useMemo(() => {
        let sortableData = [...data];

        if (sortConfig.key === 'candidate_attributes') {
            sortableData.sort((a, b) => {
            const aValue = a.candidate_attributes[0]?.attribute_type_name || '';
            const bValue = b.candidate_attributes[0]?.attribute_type_name || '';
            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
            });
        }
        else if (sortConfig.key) {
            sortableData.sort((a, b) => {
                const aValue = a[sortConfig.key] === 'N/A' ? -Infinity : a[sortConfig.key];
                const bValue = b[sortConfig.key] === 'N/A' ? -Infinity : b[sortConfig.key];
                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const [openRow, setOpenRow] = useState(null);

    const handleRowClick = (rowId) => {
        setOpenRow(openRow === rowId ? null : rowId);
    };

    return (
        <Layout searchQuery={searchQuery}>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>How'd we get this score?</DialogTitle>
                <DialogContent>
                    {dialogLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        evidence.map((item) => (
                            <Box key={item.id} sx={{ marginBottom: 2, border: '3px solid black', borderRadius: '8px', padding: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography variant="body1">
                                        <strong>
                                            {item.source_type === 'bill' ? (
                                            <>
                                                {item.reason}
                                                <br />
                                                Bill # {item.bill_number}
                                            </>
                                            ) : (
                                                item.source_type.charAt(0).toUpperCase() + item.source_type.slice(1) + ' Statement'
                                            )}
                                        </strong>
                                    </Typography>

                                    {item.url && (
                                        <Box sx={{ padding: 1, backgroundColor: 'lightblue', borderRadius: '8px', width: '130px', textAlign: 'center' }}>
                                            <Typography variant="body1">
                                                <a href={item.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>Link to Source</a>
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>

                                <Box sx={{ marginTop: 2, borderRadius: '8px', padding: 2 }}>
                                    <Typography variant="body1"><strong>{item.notification_reason}</strong></Typography>
                                </Box>

                                <Box sx={{ border: '3px solid #ccc', borderRadius: '8px', padding: 2 }}>
                                    <Typography variant="body1">{item.original_text}</Typography>
                                </Box>

                                {/* <Typography variant="body1"><strong>Original Text:</strong> {item.original_text}</Typography>
                                <Typography variant="body1">
                                    <strong>Reason:</strong> {item.reason}
                                    {item.notification_reason && ` - ${item.notification_reason}`}
                                </Typography>

                                <Typography variant="body1"><strong>Source Type:</strong> {item.source_type}</Typography>
                                <Typography variant="body1"><strong>URL:</strong> <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a></Typography> */}
                            </Box>
                        ))
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={bioDialogOpen} onClose={handleBioDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>Bio</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{bioText}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleBioDialogClose}>Close</Button>
                </DialogActions>
            </Dialog>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : sortedData.length > 0 ? (
                <Box sx={{ marginTop: 4, marginRight: 4 }}>
                    <Typography variant="body1" sx={{ display: 'inline' }}><b>Topics: </b></Typography>
                    {tags.map((tag, index) => (
                        <Typography key={tag.id} variant="body2" sx={{ display: 'inline' }}>
                            {index > 0 && ', '}
                            {tag.tag}
                        </Typography>
                    ))}
                    <br /><br />
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'position_name'}
                                            direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                            onClick={() => requestSort('position_name')}
                                        >
                                            Chamber
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'district'}
                                            direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                            onClick={() => requestSort('district')}
                                        >
                                            District
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'candidate_name'}
                                            direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                            onClick={() => requestSort('candidate_name')}
                                        >
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'party_name'}
                                            direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                            onClick={() => requestSort('party_name')}
                                        >
                                            Party
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'candidate_attributes'}
                                            direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                            onClick={() => requestSort('candidate_attributes')}
                                        >
                                            Angle(s)
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'total_score'}
                                            direction={sortConfig.key === 'total_score' ? sortConfig.direction : 'asc'}
                                            onClick={() => requestSort('total_score')}
                                        >
                                            Agreement
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'activity_level'}
                                            direction={sortConfig.key === 'activity_level' ? sortConfig.direction : 'asc'}
                                            onClick={() => requestSort('activity_level')}
                                        >
                                            Activity Level
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.map((item) => (
                                    <React.Fragment key={item.id}>
                                        <TableRow>
                                            <TableCell>{item.position_name}</TableCell>
                                            <TableCell>{item.district}</TableCell>
                                            <TableCell>
                                                <Box display="flex" alignItems="center">
                                                    {item.photo_url ? (
                                                        <Link to={`/candidate/${item.id}`}>
                                                            <img
                                                                src={item.photo_url}
                                                                alt={item.candidate_name}
                                                                style={{ objectFit: 'cover', width: '40px', height: '40px', borderRadius: '50%', marginRight: '8px' }}
                                                            />
                                                        </Link>
                                                    ) : (
                                                        <Box
                                                            sx={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                backgroundColor: 'gray',
                                                                marginRight: '8px',
                                                            }}
                                                        />
                                                    )}
                                                    <Link to={`/candidate/${item.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        {item.candidate_name}
                                                    </Link>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{item.party_name}</TableCell>
                                            <TableCell>
                                                {item.candidate_attributes.map((attribute) => (
                                                    <Button key={attribute.id} onClick={() => handleBioClick(item.bio)}>
                                                        {attribute.attribute_type_name}
                                                    </Button>
                                                ))}
                                            </TableCell>
                                            <TableCell>
                                                {item.total_score}
                                                {item.total_score !== 'N/A' && (
                                                    <IconButton onClick={() => handleRowClick(item.id)}>
                                                        {openRow === item.id ? <MinusIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                            <TableCell>{item.activity_level}</TableCell>
                                        </TableRow>
                                        {openRow === item.id && (
                                            <TableRow>
                                                <TableCell colSpan={5} align="right">
                                                    <Box sx={{ marginTop: 2, maxWidth: '500px', marginLeft: 'auto' }}>
                                                        {tags.map((tag) => (
                                                            <React.Fragment key={tag.id}>
                                                                <Box sx={{ marginTop: 2, border: '1px solid', borderRadius: 2, padding: 2 }}>
                                                                    <Typography variant="body1" sx={{textAlign: 'center'}}>
                                                                        <b>{tag.tag}</b>
                                                                    </Typography>
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>Statement</TableCell>
                                                                                <TableCell align="right">Score</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {tag.statements.map((statement) => (
                                                                                <TableRow key={statement.id}>
                                                                                    <TableCell>{statement.statement}</TableCell>
                                                                                    <TableCell align="right">
                                                                                        {item.attributions
                                                                                            .filter(attr => attr.user_tag_statement_id === statement.id)
                                                                                            .map(attr => (
                                                                                                <React.Fragment key={attr.id}>
                                                                                                    <a href="#" onClick={() => getEvidence(attr.id)}>{attr.score}</a>
                                                                                                </React.Fragment>
                                                                                            ))} 
                                                                                            
                                                                                        {item.attributions.filter(attr => attr.user_tag_statement_id === statement.id).length === 0 && (
                                                                                            <Typography variant="body2">N/A</Typography>
                                                                                        )}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </Box>
                                                            </React.Fragment>
                                                        ))}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Typography variant="h6">
                        No Results Found
                    </Typography>
                </Box>
            )}
        </Layout>
    );
}

export default ScorecardPage;