import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from './Layout';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, TableSortLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function FilePage() {
  const { id: chatbotId } = useParams(); // Get the chatbot ID from the URL
  const userId = localStorage.getItem('user_id'); // Get the user ID from local storage
  const [searchQuery, setSearchQuery] = useState('');
  const [level, setLevel] = useState('');
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [open, setOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = () => {
    fetch(`${process.env.REACT_APP_API_URL}/file?user_id=${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setFiles(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching files:', error);
        setIsLoading(false);
      });
  };

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

const handleSubmit = () => {
    // Make API call to save the file
    fetch(`${process.env.REACT_APP_API_URL}/file/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify({ chatbot: chatbotId, level, name, url }),
    })
        .then(response => response.json())
        .then(data => {
            console.log('File submitted:', data);
            // Refresh the files list
            fetchFiles();
            // Clear the form
            setLevel('');
            setName('');
            setUrl('');
        })
        .catch(error => {
            console.error('Error submitting file:', error);
        });
};

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedFiles = (files) => {
    return [...files].sort((a, b) => {
      const aValue = a[orderBy] ?? '';
      const bValue = b[orderBy] ?? '';
      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });
  };

  const handleDeleteClick = (file) => {
    setFileToDelete(file);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFileToDelete(null);
  };

  const handleDeleteConfirm = () => {
    // Make API call to delete the file
    fetch(`${process.env.REACT_APP_API_URL}/file/${fileToDelete.id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
    })
      .then(response => {
        if (response.ok) {
          console.log('File deleted');
          // Refresh the files list
          fetchFiles();
        } else {
          console.error('Error deleting file');
        }
        handleClose();
      })
      .catch(error => {
        console.error('Error deleting file:', error);
        handleClose();
      });
  };

  return (
    <Layout searchQuery={searchQuery}>
      <Box sx={{ marginTop: 2, display: 'flex', gap: 2 }}>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="level-select-label">Level</InputLabel>
          <Select
            labelId="level-select-label"
            value={level}
            label="Level"
            onChange={handleLevelChange}
          >
            <MenuItem value="Federal">Federal</MenuItem>
            <MenuItem value="State">State</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Name"
          value={name}
          onChange={handleNameChange}
          sx={{ flex: 1 }}
        />
        <TextField
          label="URL"
          value={url}
          onChange={handleUrlChange}
          sx={{ flex: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ flexShrink: 0 }}>
          Save
        </Button>
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="files table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'level'}
                    direction={orderBy === 'level' ? order : 'asc'}
                    onClick={() => handleRequestSort('level')}
                  >
                    Level
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'url'}
                    direction={orderBy === 'url' ? order : 'asc'}
                    onClick={() => handleRequestSort('url')}
                  >
                    URL
                  </TableSortLabel>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedFiles(files).map((file, index) => (
                <TableRow key={index}>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>{file.level}</TableCell>
                  <TableCell sx={{ maxWidth: 200, wordWrap: 'break-word' }}>{file.url}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleDeleteClick(file)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

export default FilePage;