import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from './Layout';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';

const CandidatePage = () => {
    const { id } = useParams(); // Extract candidate ID from the URL
    const [candidate, setCandidate] = useState(null); // Store candidate data
    const [urls, setUrls] = useState([]); // Store candidate URLs
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(''); // Error message

    useEffect(() => {
        const fetchCandidateData = async () => {
            try {
                const candidateResponse = await fetch(`${process.env.REACT_APP_API_URL}/candidates/${id}`, {
                    headers: {
                        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!candidateResponse.ok) {
                    throw new Error('Failed to fetch candidate data');
                }
                const candidateData = await candidateResponse.json();
                setCandidate(candidateData);

                const urlsResponse = await fetch(`${process.env.REACT_APP_API_URL}/candidates/urls/${id}`, {
                    headers: {
                        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!urlsResponse.ok) {
                    throw new Error('Failed to fetch candidate URLs');
                }
                const urlsData = await urlsResponse.json();
                setUrls(urlsData);

                setLoading(false);
            } catch (err) {
                console.error(err);
                setError('Could not load candidate data');
                setLoading(false);
            }
        };

        fetchCandidateData();
    }, [id]);

    if (loading) {
        return (
            <Layout>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            </Layout>
        );
    }

    if (error) {
        return (
            <Layout>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <Alert severity="error">{error}</Alert>
                </Box>
            </Layout>
        );
    }

    if (!candidate) {
        return (
            <Layout>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <Typography variant="h6">Candidate not found</Typography>
                </Box>
            </Layout>
        );
    }

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                {/* Candidate Photo */}
                <Box display="flex" alignItems="center" mb={3}>
                    <img
                        src={candidate.photo_url}
                        alt={candidate.name}
                        style={{
                            borderRadius: '50%',
                            width: '120px',
                            height: '120px',
                            marginRight: '16px',
                            objectFit: 'cover',
                        }}
                    />
                    <Box>
                        <Typography variant="h4" gutterBottom>{candidate.full_name}</Typography>
                        <Typography variant="h6">{candidate.position_name}</Typography>
                        <Typography variant="subtitle1">({candidate.party_name})</Typography>
                    </Box>
                </Box>

                {/* Candidate Bio */}
                <Box mb={3}>
                    <Typography variant="h5" gutterBottom>Bio</Typography>
                    <Typography>{candidate.bio || 'No bio available'}</Typography>
                </Box>

                {/* Contact Information */}
                <Box>
                    <Typography variant="h5" gutterBottom>Contact Information</Typography>
                    {urls.map((url) => (
                        <Typography key={url.id}>
                            {url.type === 'email' && `Email: ${url.url}`}
                            {url.type === 'phone' && `Phone: ${url.url}`}
                            {url.type === 'social' && (
                                <a href={url.url} target="_blank" rel="noopener noreferrer">
                                    {url.url}
                                </a>
                            )}
                        </Typography>
                    ))}
                </Box>

                {/* District Address */}
                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>District Office</Typography>
                    <Typography>
                        {candidate.district_office_address || 'N/A'}
                    </Typography>
                    <Typography>
                        {candidate.district_office_city && candidate.district_office_state && candidate.district_office_zip
                            ? `${candidate.district_office_city}, ${candidate.district_office_state}, ${candidate.district_office_zip}`
                            : 'N/A'}
                    </Typography>
                </Box>
            </Box>
        </Layout>
    );
};

export default CandidatePage;
