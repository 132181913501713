import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Layout from './Layout';

function SourcePage() {
    const [searchQuery, setSearchQuery] = useState('');
    const { id: fileId } = useParams(); // Get the file ID and query param from the URL
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const original = queryParams.get('original');
    const [sourceUrl, setSourceUrl] = useState(`https://api.civiclyenvolved.com/file-text/${fileId}?q=${encodeURIComponent(original)}`);

    return (
        <Layout searchQuery={searchQuery}>
            {sourceUrl && (
                <iframe
                    src={sourceUrl}
                    title="Source Document"
                    style={{ width: '90%', height: '100vh', border: '2px solid black', margin: '0 auto' }}
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                />
            )}
        </Layout>
    );
}

export default SourcePage;