import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from './Layout';
import { Box, Tooltip } from '@mui/material';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import Link from '@mui/icons-material/Link';

function MessagePage() {
  const [searchQuery, setSearchQuery] = useState('');
  const { id: chatbotId } = useParams(); // Get the chatbot ID from the URL
  const [messages, setMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [runId, setRunId] = useState(null);
  const [doPoll, setDoPoll] = useState(false);
  const [pollNum, setPollNum] = useState(0);

  useEffect(() => {
    // Fetch previous messages on load
    const fetchPreviousMessages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/chatbot-history?chatbot_id=${chatbotId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
          }
        });
        const data = await response.json();
  
        const newMessages = data.flatMap(item => [
          { content: item.question_text, direction: 'outgoing' },
          { content: item.answer_text, direction: 'incoming' }
        ]);
  
        setMessages(newMessages);
      } catch (error) {
        console.error('Error fetching previous messages:', error);
      }
    };
  
    fetchPreviousMessages();
  }, [chatbotId]);

  const handleSendMessage = async (message) => {
    setMessages([...messages, { content: message, direction: 'outgoing' }]);

    const thinkingMessage = { content: "We're thinking...", direction: 'incoming' };
    setMessages(prevMessages => [...prevMessages, thinkingMessage]);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chatbot/${chatbotId}/message/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          question: message
        })
      });
      const data = await response.json();
      setThreadId(data.thread_id);
      setRunId(data.run_id);
      setDoPoll(true); // Start polling after sending the message
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  useEffect(() => {
    let interval;
    let isRequestInProgress = false;
  
    if (doPoll) {
      interval = setInterval(async () => {
        if (isRequestInProgress) return;
  
        isRequestInProgress = true;
  
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/chatbot/${chatbotId}/poll/?thread_id=${threadId}&run_id=${runId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
            }
          });
          const data = await response.json();
          if (data.answer != null) {
            setMessages(prevMessages => {
              const newMessages = [...prevMessages];
              newMessages[newMessages.length - 1] = { content: data.answer, direction: 'incoming' };
              return newMessages;
            });
            setDoPoll(false);
            setPollNum(0);
          } else {
            setPollNum(prevPollNum => {
              let newMessage = '';
              const newPollNum = prevPollNum + 1;
              if (newPollNum > 5) {
                newMessage = "We're still working...";
  
                if (newPollNum > 10) {
                  newMessage = "We're still working... This is taking longer than expected. Please be patient.";
                }
  
                setMessages(prevMessages => {
                  const newMessages = [...prevMessages];
                  newMessages[newMessages.length - 1] = { content: newMessage, direction: 'incoming' };
                  return newMessages;
                });
              }
              return newPollNum;
            });
          }
        } catch (error) {
          console.error('Error polling:', error);
        } finally {
          isRequestInProgress = false;
        }
      }, 2000);
    }
  
    return () => clearInterval(interval); // Cleanup interval on component unmount or when doPoll changes
  }, [doPoll, chatbotId, threadId, runId]);

  return (
    <Layout searchQuery={searchQuery}>
      <Box sx={{ height: '90vh', width: '55vw' }}>
        <MainContainer>
          <ChatContainer>
            <MessageList>
              {messages.map((message, index) => (
                <Tooltip title={message.original || ''} key={index}>
                  <div style={{ textDecoration: 'none', position: 'relative' }}>
                    <Message
                      model={{
                        message: (message.direction === 'incoming' ? "<b>Answer:</b><br />" + message.content : "<b>Question:</b><br />" + message.content),
                        sentTime: 'just now',
                        sender: 'User',
                        direction: message.direction,
                      }}
                    />            
                  </div>
                </Tooltip>
              ))}
            </MessageList>
            <MessageInput
              placeholder="Type a message..."
              onSend={handleSendMessage}
              attachButton={false}
            />
          </ChatContainer>
        </MainContainer>
        <br />
        <b>Disclaimer:</b> AI is experimental and may not always provide accurate answers. Please check the source documents for the most accurate information.
      </Box>

      <svg style={{ display: 'none' }}>
        <symbol id="link-icon" viewBox="0 0 24 24">
          <path d="M3.9,12.5c0-0.6,0.2-1.2,0.7-1.6l3.3-3.3c0.9-0.9,2.3-0.9,3.2,0c0.9,0.9,0.9,2.3,0,3.2l-1.1,1.1c-0.4,0.4-0.4,1,0,1.4
            c0.4,0.4,1,0.4,1.4,0l1.1-1.1c1.7-1.7,1.7-4.5,0-6.2c-1.7-1.7-4.5-1.7-6.2,0l-3.3,3.3c-1.7,1.7-1.7,4.5,0,6.2c0.4,0.4,1,0.4,1.4,0
            C3.7,13.7,3.9,13.1,3.9,12.5z M20.1,11.5c0-0.6-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,0.4,1,0,1.4
            l-3.3,3.3c-0.9,0.9-2.3,0.9-3.2,0c-0.9-0.9-0.9-2.3,0-3.2l1.1-1.1c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-1.1,1.1
            c-1.7,1.7-1.7,4.5,0,6.2c1.7,1.7,4.5,1.7,6.2,0l3.3-3.3C19.9,12.7,20.1,12.1,20.1,11.5z"/>
        </symbol>
      </svg>
    </Layout>

    
  );
}

export default MessagePage;